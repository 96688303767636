import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { TrialForm } from '../components/form/TrialForm'
import theme from '../theme'

const TrialPage = () => {
  return (
    <Layout
      justifyCenter
      title="Get started"
      description="Get started with a free 14-day trial. Free plan available for startups with up to 9 developers."
    >
      <Background>
        <CardContainer>
          <Card>
            <h1>Get started with Swarmia</h1>
            <p className="lead">
              Start your 14-day trial or sign up to a free account (available
              for companies with up to 9 developers).
            </p>
            <TrialForm formId="a2dd5017-a25e-4aaf-ba18-82ec79e4562b" />
          </Card>
        </CardContainer>
      </Background>
    </Layout>
  )
}

const Background = styled.div`
  background: linear-gradient(180deg, #f0f8ff 0%, #f9f3fe 100%);
  position: relative;
`

const CardContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${theme.space[8]} 0 245px 0;

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    height: 100%;
    padding: 0;
  }
`

const Card = styled.div`
  width: 850px;
  background: #ffffff;
  box-shadow: 0px 14px 50px rgba(48, 49, 146, 0.1);
  border-radius: 32px;
  padding: ${theme.space[8]};

  text-align: center;

  .lead {
    margin: 0 auto ${theme.space[8]} auto;
    width: 545px;
  }

  @media screen and (max-width: ${theme.breakpoints['medium']}) {
    width: 100%;
    height: 100%;
    text-align: left;
    padding-left: ${theme.space[5]};
    padding-right: ${theme.space[5]};
    border-radius: 0px;

    .lead {
      margin: 0;
      width: 100%;
    }
  }
`

export default TrialPage
